import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Iconpdf from 'assets/pdf/iconpdf';
import Dialog from '@mui/material/Dialog';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import profileimage from '../assets/images/profileimage.webp'

function DetalleIncauto(props) {
  const {data} = useParams();
  const [rows, setRows] = useState()
  const [balanceatraso, setBalanceatraso] = useState([])
  const [cuotavencida, setCuotavencida] = useState([])

  const [expanded, setExpanded] = useState(0);
  const [currentItem,setCurrentItem] = useState()
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {

    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'id':data,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/detalleincauto',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
            setRows(data["misolicit"])            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
                        
          })
      }})
  },[]);
  
  function getAtraso(noprest){
    const value = balanceatraso.filter(value=>value.id==noprest)
    if(value.length!=0){
        return value[0]
    }else{
        return {proyectado:0,cantcuotas:0}
    }
    
  }

    return(
        <Box>
        {rows!=undefined?
        <Grid container marginTop={5} style={{backgroundColor:"white",padding:5}}>
            <Grid item lg={6} padding={5} >
                <p><strong style={{color:"#5e5757"}}>Nombre:</strong>{rows["nombres"]} </p>
                <p><strong style={{color:"#5e5757"}}>Apellido:</strong>{rows["apellidos"]} </p>
                <p><strong style={{color:"#5e5757"}}>Apodo:</strong>{rows["apodo"]} </p>
                <p><strong style={{color:"#5e5757"}}>Cedula:</strong>{rows["cedula"]} </p>
                <p><strong style={{color:"#5e5757"}}>Direccion:</strong>{rows["direccion"]} </p>
                <p><strong style={{color:"#5e5757"}}>Telefono:</strong>{rows["telefono"]} </p>
                <p><strong style={{color:"#5e5757"}}>Celular:</strong>{rows["celular"]} </p>
                <p><strong style={{color:"#5e5757"}}>Comentario:</strong>{rows["comentario"]} </p>
                <p><strong style={{color:"#5e5757"}}>Estado civil:</strong>{rows["ecivil"]} </p>
                <p><strong style={{color:"#5e5757"}}>Trabajo:</strong>{rows["empresa"]} </p>
                <p><strong style={{color:"#5e5757"}}>Direccion trabajo:</strong>{rows["dirtrab"]} </p>
                <p><strong style={{color:"#5e5757"}}>Tel. Trabajo:</strong>{rows["teltrab"]} </p>
                <p><strong style={{color:"#5e5757"}}># Prestamo:</strong> {rows["noprest"]}</p>
                <p><strong style={{color:"#5e5757"}}>Chasis:</strong>{rows["chasis"]} </p>
                


            </Grid>

            <Grid item lg={6} padding={5} >
              <p><strong style={{color:"#5e5757"}}>Fiador:</strong>{rows["fiador"]} </p>
              <p><strong style={{color:"#5e5757"}}>Fiador Ced:</strong>{rows["fiadorced"]} </p>
              <p><strong style={{color:"#5e5757"}}>Celular. Fiador:</strong>{rows["fiadortel"]} </p>
              <p><strong style={{color:"#5e5757"}}>Direccion Fiador:</strong>{rows["fiadordir"]} </p>

            </Grid>

        </Grid>
        :
        <Box height={350} display={"flex"} justifyContent={"center"} justifyItems={"center"} alignItems={"center"}>
            <p  style={{textAlign:"center",alignSelf:"center",fontWeight:"bold",fontSize:18}}>No hay datos</p>
        </Box>

        }
    </Box>

    );    
}
export default DetalleIncauto


