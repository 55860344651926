import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Prestamos from './prestamos';
import Iconpdf from 'assets/pdf/iconpdf';
import Dialog from '@mui/material/Dialog';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import profileimage from '../assets/images/profileimage.webp'

function Detalleexpediente(props) {
  const {id,data} = useParams();
  const [rows, setRows] = useState([])
  const [balanceatraso, setBalanceatraso] = useState([])
  const [cuotavencida, setCuotavencida] = useState([])

  const [expanded, setExpanded] = useState(0);
  const [currentItem,setCurrentItem] = useState()
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {

    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'cedula':data,'id':id}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperarexpediente',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             setRows(data["balanceactual"])
             setBalanceatraso(data["balanceatraso"])
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);
  
  function getAtraso(noprest){
    const value = balanceatraso.filter(value=>value.id==noprest)
    if(value.length!=0){
        return value[0]
    }else{
        return {proyectado:0,cantcuotas:0}
    }
    
  }

    return(
        <Box marginLeft={5} marginRight={5}>
            <Box>
                <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} container marginTop={8} spacing={0} style={{marginBottom:5,borderRadius:10,backgroundColor:"white"}}>
                    <Box>
                        <img src={profileimage} style={{backgroundColor:"grey",width:"130px",height:"130px",marginTop:"15px",marginLeft:"25px",borderRadius:"100px"}}></img>
                    </Box>
                    
                    <Box marginLeft={5} marginTop={2}>
                        <Typography variant="h5" marginTop={"20px"} fontWeight={"bold"} style={{alignSelf:"center"}}>{rows.length!=0?rows[0]["nombres"]:''}</Typography>
                        <Typography variant="h5" fontWeight={"bold"} style={{alignSelf:"center"}} >{rows.length!=0?rows[0]["cedula"]:''}</Typography>
                    </Box>

                </Box>
            </Box>

            <Box marginTop={5}>
                    {rows.map((value,i)=>(
                        <Accordion expanded={expanded === i} onChange={handleChange(i)}>
                          
                            <AccordionSummary
                                onClick={()=>{setCurrentItem(value)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{fontWeight:"bold"}}>{`Prestamo #${value["id"]}  `} 
                                
                                {/*value["status"]=="A"?
                                  <p style={{marginTop:3,marginLeft:10,fontWeight:"bold",fontSize:14,color:'green',backgroundColor:"#edebeb",padding:3,borderRadius:8,height:30}}>Aprobado</p>                                
                                :
                                  <p style={{marginTop:3,marginLeft:10,fontWeight:"bold",fontSize:14,color:'red',backgroundColor:"#edebeb",padding:3,borderRadius:8,height:30}}>Cancelado</p>                                
                                */}
                                

                              </AccordionSummary>
                              <Box>
                                <Grid container padding={3}>
                                    <Grid item sx={6}>
                                        <p><strong style={{color:"#5e5757"}}>Balance pendiente total:</strong> { value.balanceactual}</p>
                                        <p><strong style={{color:"#5e5757"}}>Balance en atraso:</strong> { getAtraso(value["id"]).proyectado}</p>
                                        <p><strong style={{color:"#5e5757"}}>Cuotas vencidas:</strong> { getAtraso(value["id"]).cantcuotas} {getAtraso(value["id"]).tipodepago}</p>
                                    </Grid>

                                </Grid>
                              </Box>
                                

                        </Accordion>


                    ))}
            </Box>

        </Box>

    );    
}
export default Detalleexpediente


