/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator,setOpenSide, } from "context";
// Material Dashboard 2 React routes
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "routes";
import Sidenav from "examples/Sidenav";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Icon from "@mui/material/Icon";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import CssBaseline from "@mui/material/CssBaseline";
import Transferenciaschasis from "./transferenciachasis";

import Cobrosincautosfechas from "./cobrosincautosfechas";
import InformeMatriculasCobradas from "Motores/informematriculascobradas";
import Traspaso from "Motores/informetraspaso"
import InformeMotores from "./informemotores";
import Inventariomatriculas from "./inventariomatriculas";

function MainMotores() {
  const navigate = useNavigate();

  const [reportes, setReportes] = useState('');

  const [onMouseEnter,setOnMouseEnter] = useState(false)
  const [tabValue, setTabValue] = useState('Reportes');

  const [controller, dispatch] = useMaterialUIController();
  const {
    openSide,
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);


  useEffect(() => {
  
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setOpenSide(dispatch, true);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleChangeReportes = (event) => {
    setReportes(event.target.value);
  };
  
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
       setMiniSidenav(dispatch, true);
       setOnMouseEnter(false);
    }
  };

  const handleChangeTabValue = (event, value) => {
    if(value!=null){
        setTabValue(value);
    }
  };

  
  useEffect(() => {

  
  }, []);


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact  path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  
  return (
    <DashboardLayout>
    <DashboardNavbar />
        <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Prestamos Financieros"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />

        </>
      )}
      
    </ThemeProvider>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={12} style={{justifyContent:"center",alignItems:"center"}}>
            <ToggleButtonGroup
                style={{justifySelf:"center"}}
                color="primary"
                value={tabValue}
                exclusive
                onChange={handleChangeTabValue}
                >
                <ToggleButton value="Reportes">Reportes</ToggleButton>
                <ToggleButton value="Matriculas">Matriculas</ToggleButton>
                <ToggleButton value="Transferencias">Transferencias</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              {tabValue=="Transferencias"?
               <Transferenciaschasis></Transferenciaschasis>
              :''}
            </div>
            {tabValue=="Reportes"?
               <Grid container>
                 <Grid item lg={4}>
                    <FormControl fullWidth style={{height:65}}>
                      <InputLabel id="demo-simple-select-label">Reportes</InputLabel>
                      <Select
                        style={{height:50,fontSize:20}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reportes}
                        label="Reportes"
                        onChange={handleChangeReportes}>
                            <MenuItem value={"InventarioMotores"}>Inventario Motores</MenuItem>
                            <MenuItem value={"MatriculasCobradas"}>Matriculas Cobradas</MenuItem>
                            <MenuItem value={"CobrosDeIncautosPorFechas"}>Cobros De Incautos Por Fechas</MenuItem>
                            <MenuItem value={"TraspasoMotores"}>Traspaso Motores</MenuItem>
                            <MenuItem value={"MatriculasRecibidas"}>Matriculas Recibidas</MenuItem>
                            
                      </Select>
                    </FormControl>
                </Grid>
               </Grid>
            :''}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {tabValue=="Reportes"?
                <div>
                    {reportes=="InventarioMotores"?
                      <InformeMotores></InformeMotores>
                    :''}
                    {reportes=="MatriculasCobradas"?
                      <InformeMatriculasCobradas></InformeMatriculasCobradas>
                    :''}
                    {reportes=="CobrosDeIncautosPorFechas"?
                      <Cobrosincautosfechas></Cobrosincautosfechas>
                    :''}
                    {reportes=="TraspasoMotores"?
                      <Traspaso></Traspaso>
                    :''}
                    {reportes=="MatriculasRecibidas"?
                      <Inventariomatriculas></Inventariomatriculas>
                    :''}


                </div> 
            :''}
            
          </Grid>
        </Grid>

        
      </MDBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
    
}

export default MainMotores;
