import React, { useState, useContext,useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { private_excludeVariablesFromRoot, Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Administracion from 'Motores/administracion'
import Iconpdf from 'assets/pdf/iconpdf';
import Dialog from '@mui/material/Dialog';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from 'components/MDButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


function InformeMatriculasCobradas(props) {
  var tvalor = 0.0
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rows,setRows] = useState([])
  const[opendialog,setOpendialog] = useState(false)
  
  const opcionstatus = ['T=Todos','I=Incautados','V=Vendido', 'N=Normal']
  const[status,setStatus] = useState([opcionstatus[0]])
  const[sucursalnueva,setSucursalnueva] = useState("")
  const[sucursalactual1,setSucursalactual1] = useState("")
  const[chasis,setChasis] = useState("")
  const[listasucursales,setListasucursales] = useState([])
  const[sucursal,setSucursal] = useState([])
  const [presentarpdf,setPresentarpdf] = useState(false)
  
  const [controller] = useMaterialUIController()
  const {nombreNegocio} = controller

  const handledesplegarmenu = () => {
    setDesplegarmenu(!desplegarmenu);
  };
  
  const drawerWidth = 240
  
  useEffect(() => {
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setSucursal(data["sucursales"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);

 
  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'Prest. #', width: 75 },
  {
    field: 'fecha1',
    headerName: 'Fecha',
    type: "string",
    width: 150,
    editable: true,
  },
  {
    field: 'nombres',
    headerName: 'Nombres',
    width: 200,
    editable: true,
  }, {
    field: 'apellidos',
    headerName: 'Apellidos',
    type: 'string',
    width: 200,
    editable: true,
  },
  {
    field: 'cedula',
    headerName: 'cedula',
    tyle: 'string',
    sortable: false,
    width: 125,
  },
  {
    field: 'marca',
    headerName: 'Marca',
    type: 'string',
    width: 100,
  },
  {
    field: 'modelo',
    headerName: 'Modelo',
    type: 'string',
    width: 100,
  },

  {
    field: 'colore',
    headerName: 'Color',
    type: 'string',
    width: 75,
  },

  {
    field: 'chasis',
    headerName: 'Chasis',
    type: 'string',
    width: 125,
  },
  {
    field: 'valor',
    headerName: 'Valor',
    type: 'number',
    width: 75,
  },

  
];

const MyDocument = () => (
  <PDFViewer style={{height: "450px"}}>  
  <Document>
    <Page size="A4">
      <View style={{textAlign: "center", width: "94%", fontSize: 14,textTransform: "uppercase", marginTop:"15px" }} fixed> 
          <Text >{nombreNegocio}</Text>
      </View>
      
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text >Reporte de Motores</Text>
      </View>
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text>  </Text>
      </View>
          
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "gray", color: "black", marginLeft: "15px"}} fixed> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Prest#</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Fecha</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Nombres</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Apellidos</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Cedula</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Color</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Chasis</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Valor</Text>
        
      </View>
      {rows.map((filas,i) => 
        <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"15px",fontSize: 10, color: "black",marginLeft:"15px"}} > 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.id}</Text> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.fecha1}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.nombres}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.apellidos}</Text>
          <Text style={{width: "110px", justifyContent: "left"}} fixed>{filas.cedula}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.colore}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.chasis}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.valor}</Text>                 
        </View>
      )}
      {rows.forEach(function(totales){
        
      
        tvalor = tvalor + parseFloat(totales.valor.split(',').join(''))
        
      })}

      
      
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "white", color: "black", marginLeft: "15px"}} > 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Totales</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "110px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{tvalor.toFixed(2)} </Text>
        
      </View>
          
      
    </Page>
  </Document>
  </PDFViewer>
         
);


function clicktransferir(sucursalact,chasis){
   setChasis(chasis)
   setOpendialog(true)
}

function procesarrequerimiento(event){
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/informecobrosmatriculas',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setRows(data["matriculas"])
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}

function handlepdf() {
  var MyDocument
  if (rows.length == 0) {
     alert("No hay datos para procesar este reporte")
  } else {
    setPresentarpdf(true)       
  } 
}

function alertar(cellValues){
  alert(cellValues)
}

    return(
     <Box>
     <Box component="main">
        <Grid container direction="row" justifyContent="flex-start" sx={{boxShadow:10}} spacing={1} padding={3} style={{backgroundColor:"white"}}>
            <Grid item xs = {12} sm={3} md={3} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechadesde>                  
            </Grid>
            <Grid item xs = {12} sm = {3} md={3}>
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {3} md={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard" label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  sx={{ boxShadow: 5,}} 
                  onChange={(event,value)=>setNosucursal(value)}
                />                 
            </Grid>
            
            <Grid item xs = {12} sm = {12} md={12} >
              <Box display="flex">
                  <MDButton variant="gradient" color="success" style={{width:150}} onClick={()=>procesarrequerimiento()}>Procesar</MDButton> 
                  <MDButton variant="gradient" color="error" style={{width:150,marginLeft:10}} startIcon={<PictureAsPdfIcon/>} onClick={() => handlepdf()}>PDF</MDButton> 
                  {presentarpdf === true && (
                      <Dialog open={presentarpdf}
                       maxWidth = "lg"
                       fullWidth={true}
                       onClose={()=>setPresentarpdf(false)}
                       style = {{width:"100%", height:"500px"}}> 
                       <MyDocument/>     
                    </Dialog>)}
                </Box>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} marginTop={5}>
              <DataGrid
                style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                rowHeight={38}/>
            </Grid>
          

        </Grid>

     </Box>

     
     </Box>

    );    
}
export default InformeMatriculasCobradas

