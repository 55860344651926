import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chasis from '@mui/material/TextField'
import Facturacion from './facturacion';
import Iconpdf from 'assets/pdf/iconpdf';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


function InformeMotoresFacturados(props) {
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rows,setRows] = useState([])
  const[open,setOpen] = useState(false)
  const[chasisnuevo,setChasisnuevo] = useState("")
  const[noprest,setNoprest] = useState(0)
  const[chasis,setChasis] = useState("")
  const [presentarpdf,setPresentarpdf] = useState(false)
  const [sucursales,setSucursales] = useState([])
  const [sucursal,setSucursal] = useState("")

  const[controller] = useMaterialUIController()
  const {nombreNegocio} = controller
  
  var tvalor = 0.0

  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  
  
  useEffect(() => {
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setSucursal(data["sucursales"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);
  
  
  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'DOC #', width: 90 },
  {
    field: 'fecha',
    headerName: 'Fecha',
    type: "string",
    width: 150,
    editable: true,
  },
  {
    field: 'nombres',
    headerName: 'Nombres',
    width: 150,
    editable: true,
  },
  {
    field: 'apellidos',
    headerName: 'Apellidos',
    type: 'string',
    width: 110,
    editable: true,
  },
  {
    field: 'nombrecompleto',
    headerName: 'Nombres-Apellidos',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200,
    valueGetter: (params) =>
      `${params.row.nombres || ''} ${params.row.apellidos || ''}`,
  },
  {
    field: 'chasis',
    headerName: 'Chasis',
    type: 'string',
    width: 150,
  },
  {
    field: 'financia',
    headerName: 'Financiamiento',
    type: 'number',
    width: 150,
  },
  
];

function procesarrequerimiento(event){
  
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal["label"]==undefined?"":nosucursal["label"].split("-")[0],'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/informemotoresfacturados',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]
             setRows(data["facturacion"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}

function cambiarchasis(id,chasis){
  setNoprest(id)
  setChasis(chasis)
  setOpen(true)
}

function modificarchasis() {
  //backend para modificar chasis.
  let aerror = false
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'chasisacambiar': chasisnuevo,'chasis': chasis,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/modificachasisincautados',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]
           alert(data["ok"])             
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert(data["Error"])
          
        })
    }
  })
}

const MyDocument = () => (
  <PDFViewer style={{height:"550px"}}>  
  <Document>
    <Page size="A4">
      <View style={{textAlign: "center", width: "94%", fontSize: 14,textTransform: "uppercase", marginTop:"15px" }} fixed> 
          <Text >{nombreNegocio}</Text>
      </View>
      
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text >Reporte de Motores</Text>
      </View>
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text>  </Text>
      </View>
          
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "gray", color: "black", marginLeft: "15px"}} fixed> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Prest#</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Fecha</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Nombres</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Apellidos</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Chasis</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Financia</Text>
          
      </View>
      {rows.map((filas,i) => 
        <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"15px",fontSize: 10, color: "black",marginLeft:"15px"}} > 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.id}</Text> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.fecha}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.nombres}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.apellidos}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.chasis}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.financia.toFixed(2)}</Text>
           
                       
        </View>
      )}
      {rows.forEach(function(totales){
        tvalor = tvalor + parseFloat(totales.financia)
        
      })}
      <Text> </Text>
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "white", color: "black", marginLeft: "15px"}} > 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Totales</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{tvalor.toFixed(2)} </Text>
        
      </View>
          
      
    </Page>
  </Document>
  </PDFViewer>
         
);

function handlepdf() {
  var MyDocument
  if (rows.length == 0) {
     alert("No hay datos para procesar este reporte")
  } else {
    setPresentarpdf(true)       
  } 

}

    return(
     <DashboardLayout>
     <Facturacion title="Informe Motores Facturados"/>  
     <Box component="main" sx={{marginLeft:"15px",marginTop:"10px" }} >
        
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs = {12} sm={2} md={2} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechadesde>                  
            </Grid>
            <Grid item xs = {12} sm = {2} md={2}>
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {2} md={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard" label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  
                  onChange={(event,value)=>setNosucursal(value)}
                />
            </Grid>
            <Grid item xs = {12} sm = {12} md={12} >
                <Box display="flex">
                <Button variant='contained' sx={{width:"150px",height:"45px",boxShadow:5}} style={{color: "white",backgroundColor:"green"}}  onClick={()=>procesarrequerimiento()}>Procesar</Button>                 
                 <Box style={{marginLeft:"30px",backgroundColor:"red",width:"150px",borderRadius:"10px",boxShadow:5,height:"41px"}}>
                   <Iconpdf style={{width:"150px",height:"41px"}} width="150px" onClick={() => handlepdf()}></Iconpdf>   
                     {presentarpdf === true && (
                      <Dialog open={presentarpdf}
                       maxWidth = "lg"
                       fullWidth={true}
                       onClose={()=>setPresentarpdf(false)}
                       style = {{width:"100%", height:"500px"}}> 
                       <MyDocument/>     
                    </Dialog>)}
        
             </Box>
        </Box>

            </Grid>
        </Grid>

        <Grid container style={{marginTop:"15px"}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataGrid
              style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              rowHeight={38}
            />
          </Grid>
        </Grid>           
        
        {/* Dialog Box*/}
        <Dialog
        open={open}
        style = {{width:"100%", height:"500px"}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Digite por favor el nuevo Chasis?"}
        </DialogTitle>
        <DialogContent>
             <Chasis
                onChange={(event)=>setChasisnuevo(event.target.value)}
                inputProps={{
                style: {
                  height: "5px",
                  width: "300px"
                    }
                }}
              />   
          <DialogActions>
                <Button style={{color: "white"}} variant="contained" onClick={()=>modificarchasis()}>Aceptar</Button>
                <Button style={{color: "white"}} variant="contained" onClick={()=>setOpen(false)}>Cancelar</Button>
          </DialogActions>
        </DialogContent>
       </Dialog>
     </Box>
     </DashboardLayout>

    );    
}
export default InformeMotoresFacturados

