import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Chasis from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Facturacion from './facturacion';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

var drawerWidth = 350;


function CambiarChasisFactura(props) {
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[sucursal,setSucursal] = useState("")
  const[rows,setRows] = useState([])
  const[open,setOpen] = useState(false)
  const[chasisnuevo,setChasisnuevo] = useState("")
  const[noprest,setNoprest] = useState(0)
  const[chasis,setChasis] = useState("")
  const[sucursales,setSucursales] = useState([])  
  
  
  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'DOC #', width: 90 },
  {
    field: 'fecha',
    headerName: 'Fecha',
    type: "string",
    width: 150,
    editable: true,
  },
  {
    field: 'nombrecompleto',
    headerName: 'Nombres',
    sortable: true,
    width: 270,
    editable: true,
  },
  {
    field: 'incautador',
    headerName: 'Incautador',
    type: 'string',
    width: 200,
    editable: true,
  },
  
  {
    field: 'chasis',
    headerName: 'Chasis',
    type: 'string',
    width: 150,
  },
  {
    field: 'financia',
    headerName: 'Financiado',
    type: 'number',
    width: 100,
  },

  {
    field: ' ',
    renderCell:(params)=>{
      return(
        <Button variant="contained" style={{width: "350px",color:"white"}} onClick={()=>cambiarchasis(params.row.id,params.row.chasis)}>Cambiar</Button>
      );
    }
  },
  
];

useEffect(() => {
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           setSucursal(data["sucursales"])  
          
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          setRows([]) 
          alert(data["Error"])
          
        })
    }})
},[]);

function procesarrequerimiento(event){
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal["label"].split("-")[0],'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/informemotoresfacturados',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]
             setRows(data["facturacion"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}

function cambiarchasis(id,chasis){
    setNoprest(id)
    setChasis(chasis)
    setOpen(true)
}

function modificarchasis() {
    //backend para modificar chasis.
    let aerror = false
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'chasisacambiar': chasisnuevo,'chasis': chasis,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/modificachasisincautados',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]
             alert(data["ok"])             
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            alert(data["Error"])
            
          })
      }
    })
}

    return(
     <DashboardLayout>
     <Facturacion title={"Cambiar chasis"}/>  
     <Box component="main" sx={{marginLeft:"15px",marginTop:"10px" }} >

        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs = {12} sm={2} md={2} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechadesde>                  
            </Grid>
            <Grid item xs = {12} sm = {2} md={2}>
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {2} md={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard" label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  
                  onChange={(event,value)=>setNosucursal(value)}
                />                 
            </Grid>
            <Grid item xs = {12} sm = {12} md={12} >
                <Button style={{color: "white",backgroundColor:"green"}} variant='contained' sx={{width:"150px",height:"41px"}} onClick={()=>procesarrequerimiento()}>Procesar</Button>                 
            </Grid>
        
        </Grid>

        <Grid container style={{marginTop:"15px"}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataGrid
              style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              rowHeight={38}
            />
          </Grid>
        </Grid>

        {/* Dialog Box*/}
        <Box>
        <Dialog
        open={open}
        style = {{width:"100%", height:"500px"}}
 
      >
        <DialogTitle id="alert-dialog-title">
          {"Digite por favor el nuevo Chasis?"}
        </DialogTitle>
        <DialogContent>
             <Chasis
                onChange={(event)=>setChasisnuevo(event.target.value)}
                inputProps={{
                style: {
                  height: "5px",
                  width: "300px"
                    }
                }}
              />   
          <DialogActions>
                <Button variant="contained" style={{color: "white"}} onClick={()=>modificarchasis()}>Aceptar</Button>
                <Button variant="contained" style={{color: "white"}} onClick={()=>setOpen(false)}>Cancelar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      </Box>
     </Box>
     </DashboardLayout>

    );    
}
export default CambiarChasisFactura

