import { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box'
import List from "@mui/material/List";
import {useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Draw from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import BootstrapInput from "components/MDField";

// @mui icons
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@mui/material/styles";
import MDButton from "components/MDButton";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { position } from "stylis";
import { RemoveCircleOutline } from "@mui/icons-material";
import {Dialog,DialogContent,DialogTitle,DialogContentText,DialogActions} from "@mui/material";


function Dialogtransferir(props) {
  const pantallathme = useTheme()
  const matches = useMediaQuery(pantallathme.breakpoints.up('md'));

  const [abrirmensaje,setAbrirmensaje] = useState(false)
  const [titulo,setTitulo] = useState()
  const [mensaje,setMensaje] = useState()


  const [sucursalTrans, setSucursalTrans] = useState('') 
  const [currentsucursal, setCurrentSucursal] = useState(props.current) 
  const [comentario,setComentario] = useState('Comentario')
  const [transportista,setTransportista] = useState('Transportista')
  const [sucursales,setSucursales] = useState(props.sucursales);

  useEffect(() => {
    const newItems = sucursales.filter(item => item.label !== currentsucursal);
    setSucursales(newItems);
  
  },[]);


  const DemoPaper = styled(Paper)(({ pantallathme }) => ({
    marginLeft:10,
    marginTop:10,
    width: 280,
    height: 70,
    backgroundColor:"#c5dafc",
    padding: 0,
    textAlign: 'center',
  }));
  


  const drawerOpenStyles = () => ({
    width: matches ? 250 : 300,
    left: "initial",
    right: 0,
  });


  function createTransaction(){
    if(sucursalTrans==''){
      return;
    }

    setAbrirmensaje(true)
  }

  function confirm(){

    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'sucursal': currentsucursal,"sucursalrecep":sucursalTrans,"motores":props.transferidos,
        "comentario":comentario=="comentario"?'':comentario,"transportista":transportista=="transportista"?'':transportista,
        'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/creartransferencia',request)
    .then(function(response){
      if (response.status === 200) {
          window.location.reload(true)
      
      }
      if (response.status === 400) {
          response.json().then(function(data){
//            setRows([]) 
  //          alert(data["Error"])
            
          })
      }
    })
  
  }
  
  function DialogConfirm(props) {
    
    return (
        <Dialog
          open={props.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx = {{
            '& .MuiDialog-paper':{borderRadius:"15px"}
          }}
        >
          <DialogTitle style={{fontWeight:"bold"}}>
            Realizar Transferencia
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" >
                Autorizar transferencia desde <strong>{currentsucursal.split("-")[1]}</strong> hacia <strong>{sucursalTrans.split("-")[1]}</strong>
            </DialogContentText>
          </DialogContent>
          <Box style={{display: "flex", justifyContent: "center",marginTop:"-15px"}}>
          <DialogActions>
          <MDButton variant="gradient" color="warning" style={{width:"150px",marginTop:"15px"}} onClick={()=>{setAbrirmensaje(false)}}>
              No
            </MDButton>

            <MDButton variant="gradient" color="success" style={{width:"150px",marginTop:"15px", fontWeight:"bold"}} onClick={()=>{confirm()}}>
              si
            </MDButton>
          </DialogActions>
          </Box>
        </Dialog>
    );
  }


  return (
    <Draw
      sx={{
      '& .MuiDrawer-paper': {
       height: "100vh",
       margin: 0,
       padding: `1opx`,
       borderRadius: 0,
       boxShadow: 5,
       overflowY: "auto",
       ...(drawerOpenStyles())},
      }}
      variant = 'persistent'
      open = {true}
    >
      
      <DialogConfirm open={abrirmensaje}></DialogConfirm>

    <Box style={{margin:"2%"}}>
      <Grid
        direction="row"
        container
        justifyContent={"center"}
        textAlign={"center"}>
        



        <Box marginTop={5}>       
          <h2>Transferir</h2>
          <Autocomplete
            style={{marginTop:"15px"}}
                    variant = "standard"    
                    disablePortal
                    id="combo-box-demo"
                    options={sucursales}
                    value = {sucursalTrans}
                    onBlur={(event) => setSucursalTrans(event.target.value)}
                    sx = {{ disableUnderline: true}}
                    
                                      
                    renderInput={(params) => 
                    <TextField {...params} 
                      variant = "standard"
                      label="Buscar sucursal:" 
                      sx={{boxShadow: 4, borderRadius: 2,width:"200px" ,height:"55px",backgroundColor: "white"}}
                      InputLabelProps={{
                        shrink: "true",
                        style:{
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          disableUnderline: true,
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,disableUnderline:true,borderRadius:5,
                        style:{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginTop: "20px",  
                        }}}
           />}
          />

         </Box>

         
      </Grid>
      <Divider />
     
      <Grid container style={{marginTop:"15px"}} spacing={2}>
      <TableContainer component={Paper} style={{maxHeight:350,overflowX:"hidden"}}>
      <Table sx={{ width: 40,marginLeft:2,marginRight:2 }}  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Chasis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody  >
          {props.transferidos.map((row) => (
            <TableRow
              key={row.chasis}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.Chasis}</TableCell>
              <RemoveCircleOutline style={{color:"red",height:30,width:30,marginTop:9}} onClick={()=>{props.eliminarTransferido(row)}}></RemoveCircleOutline>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      
       </Grid>
       <Box sx={{flexDirection:"column",display:"flex"}} style={{left: 0, right: 0, bottom: 0,justifyContent: 'center', alignItems: 'center',position:"absolute"}}>
          <BootstrapInput value={transportista} onChange={(event)=>{setTransportista(event.target.value)}}  id="bootstrap-input" sx={{left: 0, right: 0,}} fullWidth />

          <BootstrapInput value={comentario} onChange={(event)=>{setComentario(event.target.value)}}  id="bootstrap-input" sx={{left: 0, right: 0,}} fullWidth />
          <Box marginTop={1}></Box>
          <MDButton variant="gradient" color="success" onClick={()=>{createTransaction()}} fullWidth>Transferir</MDButton>

       </Box>
    </Box>
    </Draw>
  );
}

export default Dialogtransferir;
