import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Administracion from './administracion';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Dialog from '@mui/material/Dialog';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Input from '@mui/material/Input';
import MDButton from 'components/MDButton';

function Cobrosincautosfechas(props) {
  var tcosto
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rows,setRows] = useState([])
  const[sucursal,setSucursal] = useState([])
  const[estatuspago,setEstatuspago] = useState("No Cobrado")
  const [presentarpdf,setPresentarpdf] = useState(false)

  const [controller] = useMaterialUIController()
  const {nombreNegocio} = controller 

  useEffect(() => {
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setSucursal(data["sucursales"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);

  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  
  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'Prest', width: 75 },
  {
    field: 'fecha',
    headerName: 'Fecha',
    type: "date",
    width: 100,
    editable: true,
  },
  {
    field: 'nombres',
    headerName: 'Nombres',
    width: 150,
    editable: true,
  },
  {
    field: 'apellidos',
    headerName: 'Apellidos',
    type: 'string',
    width: 200,
    editable: true,
  },
  {
    field: 'cedula',
    headerName: 'Cedula',
    type: 'string',
    width: 150,
  },
  
  {
    field: 'fechacobro',
    headerName: 'Fecha Cobro',
    type: 'string',
    width: 150,
  },
  {
    field: 'incautador',
    headerName: 'Incautador',
    type: 'string',
    width: 150,
  },
  {
    field: 'chasis',
    headerName: 'Chasis',
    type: 'string',
    width: 150,
  },
];

function procesarrequerimiento(event){
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal, 'estatus': estatuspago,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/cobrosincautosfechas',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setRows(data["misincautos"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}

function alertar(cellValues){
  alert(cellValues)
}

const MyDocument = () => (
    
  <Document>
    <Page size="A4">
      <View style={{textAlign: "center", width: "94%", fontSize: 14,textTransform: "uppercase", marginTop:"15px" }} fixed> 
          <Text >{nombreNegocio}</Text>
      </View>
      
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text >Reporte de Motores</Text>
      </View>
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text>  </Text>
      </View>
          
      <View style={{display: "flex", flexDirection: "row", width: "94%",height:"25px",fontSize: 10, backgroundColor: "gray", color: "black", marginLeft: "15px"}} fixed> 
          <Text style={{width: "100px", textAlign: "left"}} fixed>Prest#</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Fecha</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Nombres</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Apellidos</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Cedula</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>F.Cobro</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Incautador</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Chasis</Text>
          
      </View>
      {rows.map((filas,i) => 
        <View style={{display: "flex", flexDirection: "row",width: "94%",height:"15px",fontSize: 10, color: "black",marginLeft:"15px"}} fixed> 
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.id}</Text> 
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.fecha}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.nombres}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.apellidos}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.cedula}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.fechacobro}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.costo}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.incautador}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{filas.chais}</Text>
           
                       
        </View>
      )}
      {rows.forEach(function(totales){
        tcosto = tcosto + parseFloat(totales.costo)
        
      })}
      
      <Text> </Text>
      <View style={{display: "flex", flexDirection: "row", width: "94%",height:"25px",fontSize: 10, backgroundColor: "white", color: "black", marginLeft: "15px"}} fixed> 
          <Text style={{width: "100px", textAlign: "left"}} fixed>Totales</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>{tcosto.toFixed(2)}</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed> </Text>
        
      </View>
          
      
    </Page>
  </Document>
         
);

function handlepdf() {
  var MyDocument
  if (rows.length == 0) {
     alert("No hay datos para procesar este reporte")
  } else {
    setPresentarpdf(true)       
  } 

}

    return(
     <Box style={{backgroundColor:"white"}}>
     
     <Box component="main" sx={{marginTop:"10px",backgroundColor:"white" }} >
        
        <Grid container direction="row" justifyContent="flex-start" sx={{boxShadow:10}} spacing={1} padding={3} style={{backgroundColor:"white"}}>
            <Grid item xs = {12} sm={3} md={3} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechadesde>                  
            </Grid>
            <Grid item xs = {12} sm = {3} md={3}>
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {3} md={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  sx={{ boxShadow: 5,}} 
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard"   label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  
                  onChange={(event,value)=>setNosucursal(value)}
                />
            </Grid>
            <Grid item xs = {12} sm = {3} md={3} >

            <FormControl style={{boxShadow:5}} fullWidth>
            <Select
               defaultValue={estatuspago}
               label = "Status"
               id="demo-simple-select"
               variant="standard"
               InputProps={{
                disableUnderline: true, // <== added this  
                style: {
                  height: "40px",
                  width: "100%",
                  padding:"10px"

                }
              }}
              sx={{ boxShadow: 5,}} 
               style = {{width: "100%",height:"55px",backgroundColor:"white",borderRadius:"10px",boxShadow:5}}
               onChange={(event) => setEstatuspago(event.target.value)}
               InputLabelProps={{shrink: true}}
               fullWidth
            >  
               <MenuItem  value={"Status"}>Status</MenuItem>              
               <MenuItem  value={"Cobrado"}>Cobrado</MenuItem>
               <MenuItem  value={"No Cobrado"}>No Cobrado</MenuItem>
            </Select>
            </FormControl>
            </Grid>
            
            <Grid item xs = {12} sm = {12} md={12} >
              <Box display="flex">
                <MDButton variant="gradient" color="success" style={{width:150}} onClick={()=>procesarrequerimiento()}>Procesar</MDButton> 
                <MDButton variant="gradient" color="error" style={{width:150,marginLeft:10}} startIcon={<PictureAsPdfIcon/>} onClick={() => handlepdf()}>PDF</MDButton> 
              </Box>

            </Grid>
            <Grid item sx={12} sm={12} md={12} lg={12} marginTop={5}>
              <DataGrid
                style={{height:400,backgroundColor:"white",borderRadius:"10px"}}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                rowHeight={38}/>
          </Grid>
            
        </Grid>

        
     </Box>
     </Box>

    );    
}
export default Cobrosincautosfechas
