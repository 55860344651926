import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Administracion from './administracion';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

var drawerWidth = 350;

function Eliminarcobromatricula(props) {
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rows,setRows] = useState([])
  const[sucursal,setSucursal] = useState([])
  const[estatuspago,setEstatuspago] = useState("No Cobrado")
  const[open,setOpen] = useState(false)
  const[noprest,setNoprest] = useState(0)
  const[chasis,setChasis] = useState("")

  const handledesplegarmenu = () => {
    setDesplegarmenu(!desplegarmenu);
  };
  
  const drawerWidth = 240


  useEffect(() => {
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setSucursal(data["sucursales"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);


  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  
  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'Prest', width: 50 },
  {
    field: 'fecha1',
    headerName: 'Fecha',
    type: "date",
    width: 100,
    editable: true,
  },
  {
    field: 'nombres',
    headerName: 'Nombres',
    width: 150,
    editable: true,
  },
  {
    field: 'apellidos',
    headerName: 'Apellidos',
    type: 'string',
    width: 150,
    editable: true,
  },
  {
    field: 'cedula',
    headerName: 'Cedula',
    type: 'string',
    width: 150,
  },
  
  {
    field: 'marca',
    headerName: 'Marca',
    type: 'string',
    width: 100,
  },
  {
    field: 'modelo',
    headerName: 'Modelo',
    type: 'string',
    width: 100,
  },
  {
    field: 'colore',
    headerName: 'Color',
    type: 'string',
    width: 100,
  },
  {
    field: 'chasis',
    headerName: 'Chasis',
    type: 'string',
    width: 100,
  },
  {
    field: 'valor',
    headerName: 'Valor',
    type: 'string',
    width: 100,
  },
  
  {
    field: ' ',
    renderCell:(params)=>{
      return(
        <Button variant="contained" style={{width: "350px"}} onClick={()=>eliminarcobromatricula(params.row.id,params.row.chasis)}>Eliminar</Button>
      );
    }
  },
  
];

function eliminarcobromatricula(noprest,chasis){
  setNoprest(noprest)
  setChasis(chasis)
  setOpen(true)
}

function eliminarcobromatricula1() {  
  setOpen(false)
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'noprest': noprest,'chasis': chasis,'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/eliminarcobrosmatriculas',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             alert(data["ok"])
             setRows([])
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}

function procesarrequerimiento(event){
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal, 'estatus': estatuspago,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/cobrosmatriculasporfechas',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setRows(data["matriculas"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}


const handleClose = () => {
  setOpen(false);
};

function alertar(cellValues){
  alert(cellValues)
}

    return(
     <DashboardLayout>
     <Administracion title="Eliminar Cobro Matriculas"/> 
     <Box component="main" sx={{marginLeft:"15px",marginTop:"10px" }} >
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs = {12} sm={3} md={3} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechadesde>                  
            </Grid>
            <Grid item xs = {12} sm = {3} md={3}>
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {3} md={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard" label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  
                  onChange={(event,value)=>setNosucursal(value)}
                />
            </Grid>
            <Grid item xs = {12} sm = {12} md={12} >
                <Button variant='contained' style={{color:"white",background:"green"}} sx={{width:"150px",height:"41px"}} onClick={()=>procesarrequerimiento()}>Procesar</Button>                 
            </Grid>
            
        </Grid>

        <Grid container style={{marginTop:"15px"}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataGrid
              style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              rowHeight={38}
            />
          </Grid>
        </Grid>           
     </Box>

     {/*ventana de dialogo*/}
     <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style = {{width:"100%", height:"500px"}}
 
      >
        <DialogTitle id="alert-dialog-title">
          {"Eliminacion Registro"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                Desea Eliminar este registro de Incauto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style = {{color: "white"}} onClick={eliminarcobromatricula1}>SI</Button>
          <Button style = {{color: "white"}} onClick={handleClose} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>

      </DashboardLayout> 

    );    
}
export default Eliminarcobromatricula
