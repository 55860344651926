import React, { useState,useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Administracion from './administracion';
import theme from "assets/theme";
import Iconpdf from 'assets/pdf/iconpdf';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import { WindowSharp } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {TextField} from '@mui/material';

import Sidenav from "examples/Sidenav";

export default function ConsultaMatriculas() {
  var tcosto = 0.00
  var tvalor = 0.00

  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rowsdata,setRowsdata] = useState([])
  const[rowsdata2,setRowsdata2] = useState([])
  const[opendialog,setOpendialog] = useState(false)
  const[mensaje,setMensaje] = useState("")

  
  const opcionstatus = ['T=Todos','I=Incautados','V=Vendido', 'N=Normal']
  const[status,setStatus] = useState(opcionstatus[0])
  const[sucursalnueva,setSucursalnueva] = useState("")
  const[sucursalactual1,setSucursalactual1] = useState("")
  const[chasis,setChasis] = useState("")
  const[listasucursales,setListasucursales] = useState([])
  const { breakpoints,typography } = theme;
  const drawerWidth = 240
  const [abrirpdf,setAbrirpdf] = useState(false)
  const[sucursal,setSucursal] = useState([])
    
  
  const [presentarpdf,setPresentarpdf] = useState(false)
  const theme1 = useTheme()
  const matches = useMediaQuery(theme1.breakpoints.down('sm'))

  const[controller] = useMaterialUIController()
  const {nombreNegocio,roleWeb} = controller 
  


useEffect(() => {
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/consultarmatricula',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           setRowsdata(data["matriculas"])
           setRowsdata2(data["matriculas2"])  
          
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert(data["Error"])
          
        })
    }})
},[]);

function handlechasis(value){
  setChasis(value)

}

function buscar(){
  var item = rowsdata2.filter(value => value['chasis']===chasis);    
  if(item.length!=0){
    setMensaje("Matricula disponible")
  }

}


    return(
      <DashboardLayout>


     <Administracion title="Consulta de Matriculas"></Administracion>  
    
     
     <Box component="main" sx={{marginLeft:"15px",marginTop:"10px" }} > 
     <Grid container style={{marginTop:"55px"}} display={"flex"} justifyContent={"center"} justifyItems={"center"} alignItems={"center"}>
              <Grid item md={8} lg={4} display={"flex"} flexDirection={"column"}style={{backgroundColor:"white",borderRadius:"10px",padding:10}} sx={{boxShadow:3}}>
                <Typography variant="5" fontWeight={"bold"} fontSize={18} color={"#3c403d"}>Buscar matricula</Typography>   
                <Autocomplete
                  style={{marginTop:"15px"}}
                  variant = "standard"    
                  disablePortal
                  id="combo-box-demo"
                  options={rowsdata}
                  sx = {{ disableUnderline: true}}
                  value = {chasis}
                  onBlur={(event) => handlechasis(event.target.value)}
                                      
                  renderInput={(params) => 
                    <TextField {...params} 
                      variant = "standard"
                      label="" 
                      sx={{boxShadow: 4, borderRadius: 2, height:"55px",backgroundColor: "white"}}
                      InputLabelProps={{
                        shrink: "true",
                        style:{
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          disableUnderline: true,
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,disableUnderline:true,borderRadius:5,
                        style:{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginTop: "20px",
                      }}}
                  />}                    
                />
                <Button 
                  variant='contained' 
                  sx={{width:"150px",height:"35px",boxShadow:5}} style={{marginTop:4,alignSelf:"center",fontWeight:"bold",fontSize:12,color: "white",backgroundColor:"green"}}  
                  onClick={()=>{buscar()}}>Consultar</Button>                 
                <p style={{fontWeight:"bold",marginTop:"10px",marginLeft:"10px"}}>{mensaje}</p>  

                
              </Grid>
            </Grid>


        
     </Box>
     
     {/* Dialog Box*/}
     <Dialog
        open={opendialog}
        style = {{width:"100%", height:"500px"}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Digite por la sucursal a transferir?"}
        </DialogTitle>
        <DialogContent>
             <Sucursal
                onChange={(event)=>setSucursalnueva(event.target.value)}
                inputProps={{
                style: {
                  height: "5px",
                  width: "300px"
                    }
                }}
              />   
          <DialogActions>
                <Button variant="contained" style={{color: "white"}} onClick={()=>modificarsucursal()}>Aceptar</Button>
                <Button variant="contained" style={{color: "white"}} onClick={()=>setOpendialog(false)}>Cancelar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      
     </DashboardLayout>

    );    
}

