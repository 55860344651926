import Button from "@mui/material/Button";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function Iconpdf(props) {
    return(
    <Button
        startIcon = {<PictureAsPdfIcon style={{color:"white"}}/>}
        style = {{width:"150px",color:"white",height:"41px"}}
        onClick={props.onClick}
        disableFocusRipple
        disableRipple
        color = "primary"
        variant = "contained"
        size = "large"
        sx = {{
          '&&.MuiButton-contained': {backgroundColor: "red"},
          '&&.MuiButton-iconSizeLarge':{}
        }}
    > PDF
       
    </Button>
    
)}
