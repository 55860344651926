/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator,setOpenSide, } from "context";
// Material Dashboard 2 React routes
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "routes";
import Sidenav from "examples/Sidenav";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Icon from "@mui/material/Icon";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import CssBaseline from "@mui/material/CssBaseline";


function Dashboard() {
  const navigate = useNavigate();
  const [sales, setSales] = useState("");
  const [task, setTask] = useState("");
  const [facturacionmes,setFacturacionmes] = useState(0)
  const [cantidadmotores,setCantidadmotores] = useState(0)
  const [financiado,setFinanciado] = useState(0)
  const [pagos,setPagos] = useState(0)
  const [incautos,setIncautos] = useState(0)
  const [gastos,setGastos] = useState(0)
  const [barchart,setBarchart] = useState("")
  const [ventas,setVentas] = useState("")
  const [onMouseEnter,setOnMouseEnter] = useState(false)

  const [controller, dispatch] = useMaterialUIController();
  const {
    openSide,
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  useEffect(() => {
  
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setOpenSide(dispatch, true);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
       setMiniSidenav(dispatch, true);
       setOnMouseEnter(false);
    }
  };
  
  
  useEffect(() => {

    //recuperar el total de facturacion del mes
    const request = {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"sucursal": " ",'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/facturacionultimomes',request)
      .then(function(response){
        if (response.status === 200) {
            response.json().then(function(data){
               //rows = [data["facturacion"]]
               //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]        
               setFacturacionmes(data['facturacion'][0]['precio'])
               setCantidadmotores(data['facturacion'][0]['cantidad'])
               setFinanciado(data['prestamo'][0]['valor'])
               setPagos(data['pagos'][0]['valor'])
               setIncautos(data['incautos'][0]['valor'])
               setGastos(data['gastos'][0]['valor'])
               
               //crear objeto barchart
               var barchar = new Object()
               barchar.labels = data['datachart']['label']
               barchar.datasets = {label: "Ventas", data: data['datachart']['valor']}
               setBarchart(barchar)
        
               var mesvalor = new Object()
               mesvalor.labels = data['mesvalor']['label']
               mesvalor.datasets = {label: "Motores Facturados",data: data['mesvalor']['valor']}
               setSales(mesvalor)

               var pagosmesvalor = new Object()
               pagosmesvalor.labels = data['pagosvalor']['label']
               pagosmesvalor.datasets = {label: "Pagos Mensuales", data: data['pagosvalor']['valor']} 
               setVentas(pagosmesvalor)
            })
            
        }
        if (response.status === 400) {
            response.json().then(function(data){
              alert(data["Error"])
              
            })
        }
    })  
  
  }, []);

  const[time,setTime] = useState(Date.now())

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact  path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  
  return (
    <DashboardLayout>
    <DashboardNavbar />
        <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Prestamos Financieros"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />

        </>
      )}
      
    </ThemeProvider>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Facturacion/Mes"
                count={facturacionmes.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Facturacion Ult. Mes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Motores Vendidos"
                count={cantidadmotores}
                percentage={{
                  color: "success",
                  label: "Mes Actual",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Financiado"
                count={financiado.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
        
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Cobrados"
                count={pagos.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Incautos"
                count={incautos.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Gastos"
                count={gastos.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Cobros Ultima Semana"
                  description="Lunes a Viernes"
                  date= {new Date().toDateString()}
                  chart={barchart}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Ventas Motores"
                  description="ultimo 6 meses"
                  date={new Date().toDateString()}
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Cobros Realizados"
                  description="Cobros ultimos 6 meses"
                  date={new Date().toDateString()}
                  chart={ventas}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>


        <MDBox>
           <MDButton variant="gradient" color="info" onClick={()=>{navigate("/motores/dashaboard")}}>Pantalla Completa</MDButton>
        </MDBox>
        
      </MDBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
    
}

export default Dashboard;
